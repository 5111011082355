import { GRID_ROW_NUMBER_COL_DEF } from '@profgeosoft-ui/react';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  ACTIVITY_COLUMN_FIELD,
  CHECKBOX_SELECTION_COLUMN_FIELD,
} from 'src/components/directory-table/directory-table-mediator';

import type {
  TableSettingsService,
  TTablePinModel,
  TTableSettingsObject,
  TTableSortItem,
} from './table-settings-service';
import type { TControlView } from '../directory-service/types';
import type { GridFilterModel } from '@profgeosoft-ui/react';

import {
  checkAndFixFieldValues,
  checkAndFixFilterModel,
  checkAndFixOrderModel,
  checkAndFixPinModel,
  checkAndFixSortingModel,
  checkAndFixVisibilityModel,
  checkAndFixWidthModel,
} from './utils';

export type TSelectedCellData = { cellField: string; rowId: number };
export type TCellSelectionModel = Record<number, Record<string, boolean>>;

export const HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS = [
  ACTIVITY_COLUMN_FIELD,
  CHECKBOX_SELECTION_COLUMN_FIELD,
  GRID_ROW_NUMBER_COL_DEF.field,
];

export class TableSettingsManager {
  private tableSettingsService: TableSettingsService;
  private directoryName: string;

  @observable isSettingsOpen: boolean = false;
  @observable selectedCellData: TSelectedCellData | null = null;

  constructor(tableSettingsService: TableSettingsService, directoryName: string) {
    this.tableSettingsService = tableSettingsService;
    this.directoryName = directoryName;

    makeObservable(this);
  }

  @computed
  get settings(): TTableSettingsObject {
    return this.tableSettingsService.tableSettings?.[this.directoryName] ?? {};
  }

  @computed
  get displaySettingPinnedColumns(): string[] {
    const excludeColumns = [ACTIVITY_COLUMN_FIELD, CHECKBOX_SELECTION_COLUMN_FIELD, GRID_ROW_NUMBER_COL_DEF.field];

    const leftPinnedColumns = this.settings.pinnedColumns?.left || [];
    const rightPinnedColumns = this.settings.pinnedColumns?.right || [];

    const includedLeftColumns = leftPinnedColumns.filter((column) => !excludeColumns.includes(column));

    return [...includedLeftColumns, ...rightPinnedColumns];
  }

  @computed
  get displaySettingColumns(): string[] {
    const displayedColumns = this.settings?.columnsOrderModel?.filter(
      (column) => !this.displaySettingPinnedColumns.includes(column),
    );
    return displayedColumns ?? [];
  }

  @action.bound
  checkSettings(controlViewsMap: Map<string, TControlView>): void {
    if (!this.settings) {
      return;
    }

    let haveSettingsBeenCorrected = false;
    let correctSettingsModel: TTableSettingsObject = { ...this.settings };

    const checkPinResult = checkAndFixPinModel(controlViewsMap, this.settings.pinnedColumns);
    if (checkPinResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, pinnedColumns: checkPinResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkWidthResult = checkAndFixWidthModel(controlViewsMap, this.settings.columnsWidth);
    if (checkWidthResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, columnsWidth: checkWidthResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkVisibilityResult = checkAndFixVisibilityModel(controlViewsMap, this.settings.columnsVisibility);
    if (checkVisibilityResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, columnsVisibility: checkVisibilityResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkOrderModelResult = checkAndFixOrderModel(controlViewsMap, this.settings.columnsOrderModel);
    if (checkOrderModelResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, columnsOrderModel: checkOrderModelResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkSortingModelResult = checkAndFixSortingModel(controlViewsMap, this.settings.sortingModel);
    if (checkSortingModelResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, sortingModel: checkSortingModelResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkFilteringModelResult = checkAndFixFilterModel(controlViewsMap, this.settings.filterModel);
    if (checkFilteringModelResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, filterModel: checkFilteringModelResult.model };

      haveSettingsBeenCorrected = true;
    }

    const checkFieldValuesResult = checkAndFixFieldValues(controlViewsMap, this.settings.fieldValues);
    if (checkFieldValuesResult.isCorrected) {
      correctSettingsModel = { ...correctSettingsModel, fieldValues: checkFieldValuesResult.model };

      haveSettingsBeenCorrected = true;
    }

    if (haveSettingsBeenCorrected) {
      this.tableSettingsService.updateSettings(this.directoryName, correctSettingsModel);
    }
  }

  @action.bound
  setSelectedCellData(data: TSelectedCellData | null): void {
    this.selectedCellData = data;
  }

  @action.bound
  setSettingsOpen(value: boolean): void {
    this.isSettingsOpen = value;
  }

  @action.bound
  onColumnsOrderModelChange(columns: string[]): void {
    const newSettings = { ...this.settings, columnsOrderModel: columns };

    this.tableSettingsService.updateSettings(this.directoryName, newSettings);
  }

  @action.bound
  onSortChange(model: TTableSortItem[]): void {
    const newSettings = { ...this.settings, sortingModel: model };

    this.tableSettingsService.updateSettings(this.directoryName, newSettings);
  }

  @action.bound
  onPinChange(pinnedColumns: TTablePinModel): void {
    const newSettings = { ...this.settings, pinnedColumns };

    this.tableSettingsService.updateSettings(this.directoryName, newSettings);
  }

  @action.bound
  onWidthChange(incomeColumnsWidth: Record<string, number>): void {
    const newWidthSettings: Record<string, number> = { ...this.settings.columnsWidth, ...incomeColumnsWidth };

    this.tableSettingsService.updateSettings(this.directoryName, { ...this.settings, columnsWidth: newWidthSettings });
  }

  @action.bound
  onColumnVisibilityChange(model: Record<string, boolean>): void {
    const newSettings = { ...this.settings, columnsVisibility: model };

    this.tableSettingsService.updateSettings(this.directoryName, newSettings);
  }

  @action.bound
  onFilterChange(model: GridFilterModel): void {
    const newSettings = { ...this.settings, filterModel: model };

    this.tableSettingsService.updateSettings(this.directoryName, newSettings);
  }
}
