import type {
  TEnableIfView,
  TRestrictions,
  TValueSource,
} from 'src/services/directory-service/types/control-rules-views.type';
import type { TControlView } from 'src/services/directory-service/types/controls-views.type';

export type TRule = TEnableIfRule | TValueSourceRule | TRestrictionsRule;

export type TEnrichedRule<T = TRule> = {
  managmentControls: string[];
  rule: T;
};

export function mapControlRules(control: TControlView): TEnrichedRule[] {
  const rules: TEnrichedRule[] = [];

  if ('enableIf' in control && control.enableIf) {
    const enableIf = control.enableIf;
    rules.push(mapEnableIf({ fieldId: control.fieldId, enableIf }));
  }

  if ('valueSource' in control && control.valueSource) {
    const valueSource = control.valueSource;
    rules.push(mapValueSource({ fieldId: control.fieldId, valueSource }));
  }

  if ('restrictions' in control && control.restrictions) {
    const restrictions = control.restrictions;
    rules.push(mapRestrictions({ fieldId: control.fieldId, restrictions }));
  }

  return rules;
}

// --------------------

export type TEnableIfRule = {
  dependentField: string;
  type: 'enableIf';
  ruleView: TEnableIfView[];
};

export const mapEnableIf = ({
  fieldId,
  enableIf,
}: {
  fieldId: string;
  enableIf: TEnableIfView[];
}): TEnrichedRule<TEnableIfRule> => {
  const managmentControls: string[] = [];

  enableIf.forEach((ruleObj) => {
    managmentControls.push(ruleObj.control);
  });

  return {
    managmentControls,
    rule: {
      type: 'enableIf',
      dependentField: fieldId,
      ruleView: enableIf,
    },
  };
};

// --------------------

export type TValueSourceRule = {
  dependentField: string;
  type: 'valueSource';
  ruleView: TValueSource;
};

export const mapValueSource = ({
  fieldId,
  valueSource,
}: {
  fieldId: string;
  valueSource: TValueSource;
}): TEnrichedRule<TValueSourceRule> => {
  const managmentControls: string[] = [];

  if (!Array.isArray(valueSource.sources)) {
    if (valueSource.sources.sourceType === 'control') {
      managmentControls.push(valueSource.sources.source);
    }
  } else {
    valueSource.sources.forEach((ruleObj) => {
      if (ruleObj.sourceType === 'control') {
        managmentControls.push(ruleObj.source);
      }
    });
  }

  return {
    managmentControls,
    rule: {
      type: 'valueSource',
      dependentField: fieldId,
      ruleView: valueSource,
    },
  };
};

// --------------------

export type TRestrictionsRule = {
  type: 'restrictions';
  ruleView: TRestrictions;
};

export const mapRestrictions = ({
  fieldId,
  restrictions,
}: {
  fieldId: string;
  restrictions: TRestrictions;
}): TEnrichedRule<TRestrictionsRule> => {
  return {
    managmentControls: [fieldId],
    rule: {
      type: 'restrictions',
      ruleView: restrictions,
    },
  };
};
