import { action, flow, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';

import { ApiError, OperationAbortedError } from 'src/packages/errors';

import type { DirectoryService } from './directory-service.interface';
import type { IControl, TComplexFormView, TControlView } from './types';
import type { IDirectoriesStorage } from '../directories-storage-service/directories-storage-service';
import type { LocalizationService } from '../localization-service.ts';
import type { INotificationsService } from '../notifications-service';

import { ControlRulesService } from '../control-rules-service';

import { serializeDirectoryRecordToSave } from './directory-service.utils';
import { DirectoryRecord } from './entities/directory-record.entity';
import { createControl } from './mappers/create-control';
import { getControlsViews } from './mappers/get-controls-views';
import { OptionsService } from './options-service';
import { getObjectTypesFromControlView } from './utils';
import { ValidationService } from './validation-service';

export class AdditionalDirectoryService implements DirectoryService<TComplexFormView> {
  readonly localizationService: LocalizationService;
  readonly notificationsService: INotificationsService;
  readonly directoriesStorageService: IDirectoriesStorage;
  readonly optionsService: OptionsService;
  readonly validationService: ValidationService;

  readonly view: TComplexFormView;
  readonly directoryName: string;
  readonly entityId: string = nanoid(6);
  readonly controlRulesService: ControlRulesService;
  controlsViewsMap = observable.map<string, TControlView>();
  @observable controlViews: TControlView[] = [];
  @observable record: DirectoryRecord | null = null;

  @observable isDataFetching = false;
  @observable isAdditionalDirectoriesFetching = false;
  abortControllers = observable.map<string, VoidFunction>();

  constructor(
    directoriesStorageService: IDirectoriesStorage,
    notificationsService: INotificationsService,
    localizationService: LocalizationService,
    directoryName: string,
    view: TComplexFormView,
  ) {
    this.directoriesStorageService = directoriesStorageService;
    this.optionsService = new OptionsService(notificationsService, directoriesStorageService);
    this.localizationService = localizationService;
    this.validationService = new ValidationService(localizationService);
    this.notificationsService = notificationsService;
    this.view = view;
    this.directoryName = directoryName;
    this.controlRulesService = new ControlRulesService(directoriesStorageService);

    makeObservable(this);
  }

  @flow.bound
  async *loadAdditionalDirectory(): Promise<void> {
    this.isAdditionalDirectoriesFetching = true;

    if (!this.controlViews.length) {
      return;
    }

    const loadObjects = async (objectType: string) => {
      try {
        await this.directoriesStorageService.loadObjects(objectType, true);
      } catch (e) {
        if (e instanceof OperationAbortedError) {
          return;
        }

        console.error(e);

        if (e instanceof ApiError && e.message) {
          this.notificationsService.showErrorMessage(e.message);
          return;
        }

        this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadData');
      }
    };

    const requests: Promise<void>[] = [];

    for (const controlView of this.controlViews) {
      // временно отключенный функционал
      // if (columnsVisibility[refObjectType] === false) {
      //   continue;
      // }

      const objectTypes = getObjectTypesFromControlView(controlView);

      objectTypes.forEach((refObjectType) => {
        requests.push(loadObjects(refObjectType));
      });
    }

    await Promise.allSettled(requests);
    yield;

    this.isAdditionalDirectoriesFetching = false;
  }

  @flow.bound
  async *saveChanges(
    additionalRecordValues: Record<string, unknown> | null,
    onSuccessSave?: VoidFunction | undefined,
  ): Promise<void> {
    if (!this.record) {
      return;
    }

    this.isDataFetching = true;

    if (!this.validationService.validateRecord(this.record)) {
      this.notificationsService.showErrorMessageT('directory:errors.validation.invalidRecords');
      this.isDataFetching = false;
      return;
    }

    try {
      await this.directoriesStorageService.createObject(
        this.directoryName,
        serializeDirectoryRecordToSave(this.record),
      );
      yield;

      onSuccessSave?.();
    } catch (e) {
      yield;

      console.error(e);

      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToUpdateDirectory');
    } finally {
      this.isDataFetching = false;
    }
  }

  @action.bound
  onControlBlur(control: IControl): void {
    this.validationService.validateControl(control);
  }

  @action.bound
  onControlValueChange(control: IControl, value: unknown): void {
    control.setError(null);
    control.setValue(value);

    if (!this.record) {
      return;
    }

    this.controlRulesService.onControlValueChange(this.record.controlsMap, control.fieldId, value);
  }

  @action.bound
  initializeControls() {
    const { recordControls } = getControlsViews(this.view);

    this.controlViews = recordControls;
    this.controlRulesService.mapRulesFromControlViews(recordControls);

    recordControls.forEach((controlView) => {
      this.controlsViewsMap.set(controlView.fieldId, controlView);

      const localeKey = `labels:${controlView.fieldId}.label`;
      const defaultLabel = controlView.defaultLabel || controlView.defaultValue;

      if (!this.localizationService.i18n.exists(localeKey) && defaultLabel) {
        this.localizationService.i18n.languages.forEach((lang) => {
          this.localizationService.i18n.addResource(lang, 'labels', `${controlView.fieldId}.label`, defaultLabel);
        });
      }
    });

    const controls = recordControls.map((control) => createControl(control, this.view.required));
    const newRecord = new DirectoryRecord(controls, -1);
    this.record = newRecord;

    this.loadAdditionalDirectory();

    this.optionsService.createInitialOptions(recordControls);
    this.validationService.setControlViews(recordControls);
    this.validationService.setRequiredFields(this.view.required);
  }

  init = (): VoidFunction => {
    const optionsServiceDisposer = this.optionsService.init();
    this.initializeControls();

    return () => {
      optionsServiceDisposer();
      this.abortControllers.forEach((fn) => fn());
      this.abortControllers.clear();
    };
  };
}
