import { observable, makeObservable, action, computed } from 'mobx';

import { hasValue } from 'src/packages/utils/has-value';

import type { IControl, TControlView } from '../../types';

export class CheckboxField implements IControl<boolean> {
  readonly view: TControlView;
  readonly fieldId: string;
  readonly attrName: string | null;
  readonly attrKey: string | null;
  readonly initialValue: boolean;

  @observable value = false;
  @observable isEnteringBlocked = false;
  @observable isDisabled = false;
  @observable error: string | null = null;
  @observable isLoading = false;
  @observable isRequired = false;

  constructor(
    view: TControlView,
    attrName: string | null,
    attrKey: string | null,
    fieldId: string,
    initialValue?: boolean,
  ) {
    this.initialValue = !!initialValue;
    this.view = view;
    this.fieldId = fieldId;
    this.attrName = attrName;
    this.attrKey = attrKey;
    this.value = !!initialValue;

    makeObservable(this);
  }

  @computed
  get hasChanges(): boolean {
    if (!this.attrName) {
      return false;
    }

    return this.value !== this.initialValue;
  }

  @action.bound
  setValue(value: boolean | null): void {
    if (!hasValue(value)) {
      this.value = false;
      return;
    }

    this.value = value;
  }

  @action.bound
  setIsRequired(isRequired: boolean): void {
    this.isRequired = isRequired;
  }

  @action.bound
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @action.bound
  setError(error: string | null): void {
    this.error = error;
  }

  @action.bound
  setIsDisabled(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @action.bound
  setIsEnteringBlocked(isEnteringBlocked: boolean): void {
    this.isEnteringBlocked = isEnteringBlocked;
  }
}
