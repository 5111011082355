import { hasValue } from 'src/packages/utils/has-value';

import type { TValueSourceRule } from '../mappers';
import type { IDirectoriesStorage } from 'src/services/directories-storage-service/directories-storage-service';
import type { IControl, TDirectorySource, TValueSourceObject } from 'src/services/directory-service/types';

export const handleValueSourceRule = (
  directoriesStorageService: IDirectoriesStorage,
  controlsMap: Map<string, IControl<unknown>>,
  rule: TValueSourceRule,
): { value: unknown; valuesByPseudonims: Record<string, unknown> } => {
  const valuesByPseudonims: Record<string, unknown> = {};
  const sources = rule.ruleView.sources;

  function processNestedSource(sourceObj: TDirectorySource, value: unknown): unknown {
    const directory = directoriesStorageService.directories[sourceObj.sourceDirectory];

    if (!directory) {
      console.error(`cannot find corresponding directory with given directory name ${sourceObj.sourceDirectory}`);
      return null;
    }

    const directoryRecord = directory.find((record) => record.id === value);

    if (!directoryRecord) {
      console.error(`cannot find corresponding directory record with given record id ${value}`);
      return null;
    }

    const recordValue = directoryRecord.data[sourceObj.source];

    if (sourceObj.pseudonim) {
      valuesByPseudonims[sourceObj.pseudonim] = recordValue;
    }

    if (!hasValue(recordValue)) {
      return null;
    }

    return sourceObj.isSourceFor ? processNestedSource(sourceObj.isSourceFor, recordValue) : recordValue;
  }

  function getValueFromSources(sourceObj: TValueSourceObject): unknown {
    if (sourceObj.sourceType === 'directory') {
      if (sourceObj.isConstDirectory) {
        // добавить поддержку директорий-констант
        return null;
      }

      return null;
    } else {
      const control = controlsMap.get(sourceObj.source);

      if (!control) {
        console.error(`cannot find corresponding control with fieldId ${sourceObj.source}`);
        return null;
      }

      if (!hasValue(control.value)) {
        return null;
      }

      if (sourceObj.pseudonim) {
        valuesByPseudonims[sourceObj.pseudonim] = control.value;
      }

      return sourceObj.isSourceFor ? processNestedSource(sourceObj.isSourceFor, control.value) : control.value;
    }
  }

  if (!Array.isArray(sources)) {
    return {
      value: getValueFromSources(sources),
      valuesByPseudonims,
    };
  } else {
    return {
      value: sources.map((source) => getValueFromSources(source)),
      valuesByPseudonims,
    };
  }
};
